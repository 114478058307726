
  import NavigationButtons from '~/components/marketplace/new_check/NavigationsButtons.vue'
  import SummaryStep from '~/components/marketplace/new_check/SummaryStep.vue'
  import DialogCheckOrderPending from '~/components/marketplace/new_check/DialogCheckOrderPending.vue'

  export default {
    name: `StepperStepSummary`,
    components: {
      DialogCheckOrderPending,
      SummaryStep,
      NavigationButtons
    },
    props: {
      completedSteps: {
        type: [Array, Number],
        default: () => []
      }
    },
    data() {
      return {
        toggleFreeze: false,
        orderPending: false
      }
    },
    computed: {
      isComplete() {
        return this.completedSteps.includes(3)
      }
    },
    methods: {
      clearStep() {
        this.resetObsValidation()
        this.$emit(`clear-step`, 3)
      },
      resetObsValidation() {
        this.$refs.summaryObs.reset()
      },
      async nextStep() {
        await this.beforeOrder()
        this.$store.dispatch(`marketplace/orderNewCheck`, { form: this.getForm() })
          .then( res => this.afterOrdered(res))
          .catch( err => this.onFailOrder(err))
      },
      isObsValid() {
        return this.$refs.summaryObs.validate()
      },
      async beforeOrder() {
        await this.$emit(`order-pending`)
        this.orderPending = true
      },
      afterOrdered(res) {
        this.showSuccessMessage(res)
        this.orderPending = false
        this.$emit(`order-completed`, res.uuid)
        this.$emit(`next-step`, 4)
      },
      onFailOrder(err) {
        this.orderPending = false

        if (err?.data?.type === `error`) {
          this.$flashMessage.error({
            title: this.$i18n.t(`messages.error`),
            message: err?.data?.message
          })
        }
        this.$emit(`order-failed`)
      },
      showSuccessMessage(res) {
        this.$flashMessage.success({
          title: this.$i18n.t(`messages.success`),
          message: this.$i18n.t(`pages.marketplace_new_check.created_successfully`)
        })
      },
      showErrorMessage(err) {
        this.$flashMessage.error({
          title: this.$i18n.t(`messages.error`),
          message: err.response?._data?.message
        })
      },
      getForm() {
        return {
          category: this.$store.getters[`marketplace/selectedCategory`]?.id,
          checks: this.$store.getters[`marketplace/selectedChecks`].map((el) => el.id),
          jobLocation: this.$store.getters[`marketplace/selectedJobLocation`]?.value,
          reason: this.$store.getters[`marketplace/selectedReason`]?.value,
          typeOfDossier: this.$store.getters[`marketplace/selectedDossier`]?.value,
          candidateFormAnswers: this.$store.getters[`marketplace/candidateFormsAnswers`],
        }
      },
      freeze() {
        this.toggleFreeze = true
      },
      defrost() {
        this.toggleFreeze = false
      }
    }
  }
