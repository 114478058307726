import Vue from 'vue'

export default () => {
  Vue.prototype.$shared = {}

  Vue.prototype.$shared.isDisplayed = function (question, answers) {
    if (!question.display || !!question?.display?.displayed) {
      return true
    }

    if (!question.display.is_displayed_if || Object.keys(question.display.is_displayed_if).length === 0) {
      return false
    }

    const compareFunc = (key) => {
      const allowedAnswers = question.display.is_displayed_if[key].answers_condition;
      let chosenAnswer = answers[key];
      // if chosenAnswer is a list take the first element
      if (Array.isArray(chosenAnswer)) {
        chosenAnswer = chosenAnswer[0];
      }
      return allowedAnswers.includes(chosenAnswer);
    }

    return question?.display?.condition_type === `OR`
      ? Object.keys(question.display.is_displayed_if).some(compareFunc)
      : Object.keys(question.display.is_displayed_if).every(compareFunc)
  }
}
