

export default {
  name: `ProductInformation`,
  props: {
    show: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: undefined
    }
  },
  components: {
  },
  data() {
    return {
      shouldShow: this.show
    }
  },
  methods: {
    close() {
      this.$emit(`close`)
    }
  }
}
