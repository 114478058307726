
  import { mapGetters } from 'vuex'

  export default {
    name: `PrivacyPolicy`,
    data() {
      return {
        togglePolicy: false
      }
    },
    computed: {
      ...mapGetters({
        checks: `marketplace/selectedChecks`
      })
    },
    methods: {
      clearValues() {
        this.togglePolicy = false
      }
    }
  }
