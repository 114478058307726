

  import MyCheckWorldCheckStatus from '~/components/marketplace/my_checks/MyCheckWorldCheckStatus.vue'
  import MyCheckCreditReformStatus from '~/components/marketplace/my_checks/MyCheckCreditReformStatus.vue'
  import MyCheckDefaultStatus from '~/components/marketplace/my_checks/MyCheckDefaultStatus.vue'
  import MyCheckIDStatus from '~/components/marketplace/my_checks/MyCheckIDStatus.vue'
  import MyCheckSwissCriminalRecordStatus from '~/components/marketplace/my_checks/MyCheckSwissCriminalRecordStatus.vue'
  import MyCheckInternetProfileStatus from '~/components/marketplace/my_checks/MyCheckInternetProfileStatus.vue'
  import MyCheckMedicalTestStatus from '~/components/marketplace/my_checks/MyCheckMedicalTestStatus.vue'
  import MyCheckSedexCheckStatus from '~/components/marketplace/my_checks/MyCheckSedexCheckStatus.vue'
  import MyCheckCrifStatus from '~/components/marketplace/my_checks/MyCheckCrifStatus.vue'
  import MyCheckSedexCRCheckStatus from '~/components/marketplace/my_checks/MyCheckSedexCRCheckStatus.vue'

  export default {
    name: `MyCheckStatusFactory`,
    components: {
      MyCheckWorldCheckStatus,
      MyCheckDefaultStatus,
      MyCheckIDStatus,
      MyCheckSwissCriminalRecordStatus,
      MyCheckInternetProfileStatus,
      MyCheckMedicalTestStatus,
      MyCheckSedexCheckStatus,
      MyCheckCrifStatus,
      MyCheckSedexCRCheckStatus,
    },
    data() {
      return {
        componentByType: {
          "world_check": MyCheckWorldCheckStatus,
          "credit_reform": MyCheckCreditReformStatus,
          "identt": MyCheckIDStatus,
          "internet_profile": MyCheckInternetProfileStatus,
          "swiss_criminal_record": MyCheckSwissCriminalRecordStatus,
          "medical_test": MyCheckMedicalTestStatus,
          "sedex_check": MyCheckSedexCheckStatus,
          "crif": MyCheckCrifStatus,
          "sedex_check_swiss_criminal_record": MyCheckSedexCRCheckStatus,
          "default": MyCheckDefaultStatus
        }
      }
    },
    props: {
      myCheck: {
        type: Object,
        default: undefined
      },
    },
    computed: {
      component(){
        return Object.keys(this.componentByType).includes(this.myCheck?.type?.check_code)
          ? this.componentByType[this.myCheck.type?.check_code]
          : this.componentByType.default
      }
    }
  }
