
  import { mapGetters } from 'vuex'
  import IconBase from './IconBase.vue'
  import IconLogoAeqNoir from './icons/IconLogoAeqNoir.vue'
  import AddCredits from '~/components/accounting/AddCredits.vue'

  export default {
    name: `DrawerMenu`,
    components: {
      AddCredits,
      IconBase,
      IconLogoAeqNoir
    },
    props: {
      value: {
        type: Array,
        default: undefined
      }
    },
    data() {
      return {
        title: `Aequivalent`,
        copyright: false,
        miniVariant: false,
        websiteUrl: this.$config.public.websiteUrl
      }
    },
    computed: {
      drawerStatus() {
        return this.$store.state.drawerMenu.drawerStatus
      },
      ...mapGetters({
        innerValue: `menu/getItems`,
        isExternal: `permissions/userIsExternal`,
        userIsMaster: `permissions/userIsMaster`,
        currentCompanyCredits: `accounting/credits`,
        isGuest: `permissions/userIsGuest`,
        drawerDisplayed: `drawerMenu/getDisplayed`,
      }),
      currentPath() {
        return this.$route.path
      },
      currentLanguage() {
        const listContactlanguages = [`fr`, `de`]
        return listContactlanguages.includes(this.$i18n.locale) ? `${this.$i18n.locale}/` : ``
      }
    },
    mounted() {
      if (this.value !== undefined && Array.isArray(this.value)) {
        this.$store.commit(`menu/setItems`, this.value)
      }
    },
    methods: {
      closeDrawerMenu() {
        this.$store.commit(`drawerMenu/closeDrawer`)
      },
      updateDrawer(evt) {
        if (!this.$vuetify.breakpoint.lgOnly) {
          if (evt) {
            this.$store.commit(`drawerMenu/openDrawer`)
          } else {
            this.$store.commit(`drawerMenu/closeDrawer`)
          }
        }
      },
      async swapLang(lang) {
        this.$i18n.setLocale(lang)
        if (this.$auth.loggedIn) {
          await this.$api.userInfo.update({ lang }).then(() => {
            // Reload App
            this.$router.go()
          })
        }
      },
      clickButton(index, subIndex) {
        const item = this.innerValue?.[index]
        if (!!item.to) {
          if (subIndex !== undefined) {
            const subItem = item.items?.[subIndex]
            !!subItem.to && this.$router.push({ path: subItem.to })
          } else {
            this.$store.commit(`menu/setMenuState`, { idx: index, state: { value: true } })
            this.$router.push({ path: this.innerValue[index].to })
          }
        }
      },
      isActive(item) {
        const { to, alternativePath, items } = item
        const path = this.currentPath
        let regex = null
        let parentOpened = false
        if (alternativePath !== undefined) {
          regex = new RegExp(alternativePath, `i`)
        }
        if (items?.length) {
          parentOpened = Boolean(items.filter((item) => item.to === path.replace(/\/$/, ``)).length)
        }
        return to === path.replace(/\/$/, ``) || (alternativePath !== undefined && regex.test(path)) || parentOpened
      },
      defineToggleSubMenu() {
        return 0
      },
      formattedCredits(value) {
        return new Intl.NumberFormat(`fr-CH`, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2 }).format(value)
      }
    }
  }
