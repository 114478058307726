
  import DrawerMenu from '~/components/DrawerMenu'
  import RatingModal from "~/components/subscription/RatingModal.vue";

  export default {
    name: `AeqDefaultLayout`,
    components: {RatingModal, DrawerMenu },
    data() {
      return {
        counter: 0,
        aeqOsVersion: this.$config.public.aeqOsVersion,
        showReleaseNote: false,
        release: null,
        websiteUrl: this.$config.public.websiteUrl
      }
    },
    computed: {},
    mounted() {
      this.fetchReleaseNote();
    },
    created() {
      this.$store.dispatch(`menu/resetState`)
    },
    methods: {
      openDrawerMenu() {
        this.$store.commit(`drawerMenu/openDrawer`)
      },
      refresh() {
        this.counter = (this.counter + 1) % 2
      },
      getCompanies() {
        const companies = []
        if (this.$auth.loggedIn) {
          if (this.$store.getters[`permissions/userIsClient`]) {
            this.$store.getters[`companies/getUserCompanies`](`client`).forEach((c) => {
              companies.push(c)
            })
          }
          if (this.$store.getters[`permissions/userIsCandidate`]) {
            this.$store.getters[`companies/getUserCompanies`](`candidate`).forEach((c) => {
              if (!!companies.find((co) => co.company_tag === c.company_tag)) {
                companies.push(c)
              }
            })
          }
        }
        return companies
      },
      async fetchReleaseNote() {
        if (!this.$auth.loggedIn) {
          return;
        }
        try {
          this.release = await this.$api.releaseNotes.currentReleaseNote();
          if (this.release && !this.release.user_seen_it) {
            this.showReleaseNote = true;
          }
        } catch (error) {
          this.release = null;
        }
      },
      showReleaseNoteModal() {
        if (this.release) {
          this.showReleaseNote = true;
        }
      }
    }
  }
