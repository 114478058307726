
  export default {
    name: `DialogPreviewUploadedDoc`,
    props: {
      doc: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        toggleDialog: false,
        src: null,
      }
    },
    computed: {
      token() {
        const res = this.$router.history.current.query.token || this.$store.state.external.token
        return res
      },
    },
    methods: {
      
      previewDoc(doc){
        if (doc.content) {
          const blob = new Blob(this.$helpers.b64ToArrayBuffer(doc.content), {type: doc.content_type})
          this.src = URL.createObjectURL(blob)
        } else if (!!doc.downloadLink) {
          const getDocument = !!this.token
            ? (d) => this.$api.external.getOneDocument(d)
            : (d) => this.$api.documents.getOne(d)
          getDocument(doc.downloadLink)
            .then((res) => {
              if (!!res && !!res._data) {
                const pdfBlobUrl = res._data
                this.src = URL.createObjectURL(pdfBlobUrl)
              }
            })
            .catch((e) => {
              console.log(e)
              this.$flashMessage.error({
                title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
                message: this.$t(`messages.cant_get_document`)
              })
            })
        }
      },
      handlerClick() {
        this.previewDoc(this.doc)
        this.toggleDialog = true
      }
    }
  }
