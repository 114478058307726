
  export default {
    name: `LogoutPage`,
    options: {
      auth: false
    },
    mounted() {
      this.$store.commit(`external/setToken`, null)
      this.$store.dispatch(`logout`)
      this.logout()
    },
    methods: {
      logout() {
        this.$auth.logout()
      }
    }
  }
