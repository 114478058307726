
  import { mapState } from 'vuex'
  import SecuritySettings from '~/components/marketplace/external/SecuritySettings'

  export default {
    name: `MySettings`,
    components: {
      SecuritySettings
    },
    options: {
      auth: false
    },
    data() {
      return {
        tabs: {
          '#profile-security': {
            name: `pages.settings.my_security`,
            icon: `mdi-security`,
            componentType: `security-settings`,
            ref: `security`,
            update: `security`,
            permissions: false
          },
        }
      }
    },
    async fetch() {
    },
    head() {
      return {
        title: this.$t(`pages.layout.my_settings`)
      }
    },
    computed: {
      selectedTab: {
        get() {
          return this.$route.hash || `#profile-security`
        },
        set() {}
      },
      ...mapState({
        error: (state) => state.settings.error
      }),
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {},
    methods: {
    }
  }
