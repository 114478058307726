
  import { VueTelInputVuetify } from 'vue-tel-input-vuetify'

  export default {
    name: `AeqPhoneField`,
    components: {
      VueTelInputVuetify
    },
    props: {
      errorMessages: {
        type: [String, Array],
        default: () => []
      },
      value: {
        type: String,
        default: ``
      },
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ``
      },
    },
    data() {
      return {
        focused: false,
        innerValue: this.value,
        phoneData: null,
        filteredProps: {}
      }
    },
    computed: {
      traductedErrors() {
        return this.errorMessages
      },
      dynamicClasses() {
        let classes = this.filteredProps?.outlined !== undefined ? `phonefield--outlined` : ``
        classes += this.focused && this.errorMessages.length === 0 ? ` phonefield--is-focused` : ``
        classes += this.errorMessages.length > 0 ? ` phonefield--with-errors` : ``
        return classes
      }
    },
    watch: {
      value: {
        deep: true,
        handler(value) {
          this.innerValue = value
        }
      }
    },
    mounted() {
      this.filteredProps = { ...this.$attrs }
      if (!this.$attrs.placeholder) {
        this.filteredProps.placeholder = ``
      }
    },
    methods: {
      getInput(text, data) {
        this.innerValue = text
        this.phoneData = data
        this.$emit(`input`, data.number.international)
      },
      getCountryCode() {
        return this.phoneData.number.international.split(` `)[0]
      }
    }
  }
