
  import { mapGetters } from 'vuex'
  import DialogPreviewUploadedDoc from '~/components/marketplace/new_check/DialogPreviewUploadedDoc.vue'

  export default {
    name: `PreviewTable`,
    components: { DialogPreviewUploadedDoc },
    props: {
      form: {
        type: Object,
        default: undefined
      },
      answers: {
        type: Object,
        default: undefined
      },
    },
    computed: {
      ...mapGetters({
        countries: `formData/countries`
      }),
      orderedFormElements() {
        return this.$helpers.sortElements(this.form, (a, b) => a[1].order - b[1].order)
      },
      displayedFormElements() {
        const isQuestion = (fe) => !fe.is_question
        const isFiller = (fe) => fe.type === `filler`
        const isDisplayed = (fe) => this.$shared.isDisplayed(fe, this.answers)
        return Object.values(this.orderedFormElements).filter((fe) => (isQuestion(fe) || isDisplayed(fe)) && !isFiller(fe))
      }
    },
    methods: {
      uploadedDocs(fElement) {
        // Merge new and saved documents
        const newDocs = this.answers[fElement.title]?.new || []
        const savedDocs = this.answers[fElement.title]?.saved || []
        return [...newDocs, ...savedDocs]
      },
      getLabel(fElement) {
        const form = this.form[fElement.title]
        const isCheckBox = fElement.type === `selection_controls` && form?.answers_list.length === 1 && form.label === ``
        return isCheckBox ? form?.answers_list[0].text : form?.label
      },
      getText(fElement) {
        const form = this.form[fElement.title]
        const isCheckBox = fElement.type === `selection_controls` && form?.answers_list.length === 1 && form.label === ``
        const answer = this.answers[fElement.title]
        switch (fElement.type) {
          case `selection_controls`:
            if (isCheckBox) {
              return !!answer?.length ? this.$t(`components.yes`) : this.$t(`components.no`)
            }
            return form?.answers_list.filter(i => i.value === answer)[0]?.text
          case `select`:
            return form?.answers_list.filter(i => i.value === answer)[0]?.text
          case `country`: {
            if (form.options.multiple.value) {
              const value = answer ?? []
              return  this.countries.filter(i => value.includes(i.value)).map(i => i.text).join(`, `)
            } else {
              return this.countries.filter(i => i.value === answer)[0]?.text
            }
          }
          case `date`:
            return  !!answer ? this.$moment(answer, `YYYY-MM-DD`).format(`DD.MM.YYYY`): ``
          case `radio`:
            break
          case `input_searcher`:
            return answer?.name;
          default:
            return answer
        }
      }
    }
  }
