
  import PreviewTable from '~/components/marketplace/PreviewTable.vue'

  export default {
    name: `MarketplaceExternalPage`,
    components: { PreviewTable },
    options: {
      auth: false
    },
    data() {
      return {
        form: [],
        screener: null,
        lang: null,
        steps: null,
        headerMessage: null,
        numberStep: 3,
        currentStep: 1,
        formSent: false,
        message: undefined,
        status: `success`,
        loading: false,
        filled: false,
        checkCode: undefined,
        check: undefined,
        answers: {},
        errors: {},
      }
    },
    async fetch() {
      try {
        const {
          _data: { lang, form, answers, filled, checkCode, check }
        } = await this.$store.dispatch(`marketplaceExternal/getExternal`)
        this.lang = lang
        this.form = form
        this.answers = answers
        this.filled = filled
        this.checkCode = checkCode
        this.check = check

        for (const element in form) {
          this.resetAnswer(element)
        }
      } catch (err) {
        console.log(err)
        this.status = `error`
        if (err?.response?.status === 401) {
          this.message = err.data.detail
        } else {
          throw new Error(err)
        }
      }
    },
    computed: {
      token() {
        return this.$router.history.current.query.token || this.$store.state.external.token
      },
      orderedFormElements() {
        return this.$helpers.sortElements(this.form, (a, b) => a[1].order - b[1].order)
      },
      cols(){
        return this.groupedFormElements.length === 1 ? 12 : 6
      },
      groupedFormElements() {
        const fElements = Object.values(this.orderedFormElements)
        // get id of each section
        const sectionIdx = Object.keys(fElements).filter(name => !fElements[name].is_question).map(k => parseInt(k))
        // define two group to display values (each group is a column):
        const groups = []
        const group = (items, startIdx, endIdx) => {
          const dict = {}
          items.filter(i => items.indexOf(i) >= startIdx && items.indexOf(i) < endIdx).map(i => (dict[i.title]=i))
          return dict
        }

        if (sectionIdx.length && sectionIdx.length === 2) {
          // prefer to group by section; max 2 for now
          let startIdx = sectionIdx[0]
          let endIdx = sectionIdx[1]
          groups.push(group(fElements, startIdx, endIdx))

          startIdx = endIdx
          endIdx = fElements.length
          groups.push(group(fElements, startIdx, endIdx))
        } else if (fElements.length >= 8) {
          // define two group to show all information (one to each column): considered size: 50:50

          let startIdx = 0
          let endIdx = Math.trunc(fElements.length / 2)
          groups.push(group(fElements, startIdx, endIdx))

          startIdx = endIdx
          endIdx = fElements.length
          groups.push(group(fElements, startIdx, endIdx))
        } else {
          groups.push(this.orderedFormElements)
        }

        console.log(groups)

        return groups
      },
      intro() {
        if (this.checkCode === `swiss_criminal_record`) {
          return this.$t(`pages.marketplace_external.intro.swiss_criminal_record`);
        } else if (this.checkCode === `medical_test`) {
          return this.$t(`pages.marketplace_external.intro.medical_test`)
        } else if (this.checkCode === `sedex_check`) {
          return this.$t(`pages.marketplace_external.intro.sedex_check`)
        } else if (this.checkCode === `sedex_check_swiss_criminal_record`) {
          return this.$t(`pages.marketplace_external.intro.sedex_check_swiss_criminal_record`)
        } else {
          return this.$t(`pages.marketplace_external.intro.default`)
        }
      },
      subTitle() {
        if (this.checkCode === `swiss_criminal_record`) {
          return this.translate_with_info(`pages.marketplace_external.sub_title.swiss_criminal_record`);
        } else if (this.checkCode === `medical_test`) {
          return this.translate_with_info(`pages.marketplace_external.sub_title.medical_test`)
        } else if (this.checkCode === `sedex_check`) {
          return this.translate_with_info(`pages.marketplace_external.sub_title.sedex_check`)
        } else if (this.checkCode === `sedex_check_swiss_criminal_record`) {
          return this.translate_with_info(`pages.marketplace_external.sub_title.sedex_check_swiss_criminal_record`)
        } else {
          return this.translate_with_info(`pages.marketplace_external.sub_title.default`)
        }
      },
    },
    watch: {
      lang(value) {
        this.$i18n.setLocale(value)
      },
      form(value) {
        this.key += 1
      }
    },
    created() {
      if (!this.$router.history.current.query.token) {
        return null
      }
      this.$store.commit(`marketplaceExternal/setToken`, this.$router.history.current.query.token)
    },
    mounted() {
      this.$store.dispatch(`createMarketplaceExternalTimeout`);
    },
    updated() {},
    methods: {
      translate_with_info(code) {
        return this.$t(code, {
          first_name: this.check.candidate_first_name,
          last_name: this.check.candidate_last_name,
        })
      },
      downloadAllInfo() {
        const data = {
          'form': this.answers
        }
        this.$store.dispatch(`marketplaceExternal/generatePdfFile`, data)
          .then((res) => {
            (!!res && !!res._data) && this.$helpers.FileSaver.saveAs(new Blob([res._data]), res.headers.get(`content-filename`))
          })
          .catch((e) => {
            this.$flashMessage.error({
              title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
              message: this.$t(`messages.cant_get_document`)
            })
          })
      },
      gotoStep(index) {
        this.currentStep = index
      },
      isDisplayed(question) {
        const display = this.$shared.isDisplayed(question, this.answers)
        if (!display && question && question.title && this.answers[question.title] !== undefined) {
          this.resetAnswer(question.title);
        }
        return display
      },
      resetAnswer(name) {
        if (this.answers[name] !== undefined && this.answers[name]?.answer !== undefined) {
          console.log(`resetAnswer`, name, this.answers[name]?.answer)
          this.saveAnswers(name, this.answers[name]?.answer)
        } else if (this.form[name] && this.form[name].options?.initial?.value) {
          this.saveAnswers(name, this.form[name].options?.initial?.value)
        } else {
          this.saveAnswers(name, undefined)
        }
      },
      saveAnswers(name, value) {
        this.answers[name] = value
      },
      displayErrors(errors){
        this.$refs.marketplaceExternalFormObs.setErrors(errors)
      },
      async submitForm() {
        const valid = await this.$refs.marketplaceExternalFormObs.validate()
        if (!valid) {
          this.gotoStep(2);
          return
        }
        this.loading = true
        this.$store.dispatch(`marketplaceExternal/submitForm`, {'form': this.answers})
          .then( res => {this.formSent = true})
          .catch( err => {
            this.gotoStep(2);
            this.errors = err
            this.displayErrors(err?.data)
            this.$flashMessage.error({
              title: this.$i18n.t(`messages.error`),
              message: err?.data?.message
            })
          }).finally(() => {
            this.loading = false
          })
      }
    }
  }
