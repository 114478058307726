
  export default {
    name: `SecuritySettings`,
    props: {
      value: {
        type: Object,
        default: null
      },
      formDataSettings: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        deletion: false,
        confirmDelModal: false,
        confirmDelLocalStorage: false
      }
    },
    computed: {
      dataExist() {
        return !localStorage.getItem(`aeq-data`)
      },
    },
    created() {},
    methods: {
      async deleteAccount() {
        try {
          await this.$store.dispatch(`marketplaceExternal/deleteAccount`)
          this.deletion = true
        } catch (error) {
          this.$flashMessage.error({
            title: this.$t(`messages.error`),
            message: this.$t(`messages.unknown_error`)
          })
        }
        this.closeConfirmDelModal()
      },
      closeConfirmDelModal() {
        this.confirmDelModal = false
      },
      openConfirmDelModal() {
        this.confirmDelModal = true
      },
      closeConfirmDelLocalStorageModal() {
        this.confirmDelLocalStorage = false
      },
      openConfirmDelLocalStorageModal() {
        this.confirmDelLocalStorage = true
      },
    }
  }
