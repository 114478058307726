const EXTERNAL_NAME_ROUTE = `external`
const isExternalNameRoute = (name) => name === EXTERNAL_NAME_ROUTE

const ONBOARDING_NAME_ROUTE = `onboarding`
const isOnboardingNameRoute = (name) => name === ONBOARDING_NAME_ROUTE

const MARKETPLACE_EXTERNAL_NAME_ROUTES = [`marketplace-external`, `marketplace-external-settings`, `marketplace-external-sign_out`]
const isMarketplaceExternalNameRoute = (name) => MARKETPLACE_EXTERNAL_NAME_ROUTES.includes(name)

export default async function ({ $gates, $auth, store, route }) {
  // Fetch user at each page changing

  const needFetchUser = !!store.getters.needFetchUser || !$auth.$state.user?.companies

  if (needFetchUser) {
    await $auth.fetchUser()
    store.commit(`toggleNeedFetchUser`)
  }

  let roles = [`user`]
  let permissions = [`everyone`]

  const company = $auth.$state.user
    ? $auth.$state.user.companies[store.getters[`companies/getCurrentCompany`] || $auth.$state.user.default_company]
    : false

  console.log(`route name`, route)
  if (isMarketplaceExternalNameRoute(route?.name)) {
    permissions = roles = [`marketplace-external`]
  } else if (isExternalNameRoute(route?.name) || store.getters[`permissions/userIsExternal`]) {
    permissions = roles = [`external`]
  } else if (isOnboardingNameRoute(route?.name)) {
    permissions = roles = [`guest`]
  } else {
    // Update current roles & permissions
    if (company?.roles?.length > 0) {
      roles = roles.concat(company.roles)
    }
    if (company?.permissions?.length > 0) {
      permissions = permissions.concat(company.permissions)
    }
  }

  $gates.setRoles(roles)
  $gates.setPermissions(permissions)
}
