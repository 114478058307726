import * as Sentry from '@sentry/browser'
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default (recaptchaKey) => {
  if (!recaptchaKey) {
    Sentry.setContext(`Details`, {
      recaptchaKey
    })
    Sentry.captureException(`Recaptcha key not provided.`)
    return
  }
  Vue.use(VueReCaptcha, {
    siteKey: recaptchaKey,
    loaderOptions: {
      useRecaptchaNet: true
    }
  })
}
