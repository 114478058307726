import HttpFactory from '../factory'

class OnboardingModule extends HttpFactory {
  RESOURCE = `/onboarding`

  async send(form) {
    return await this.call(`post`, `${this.RESOURCE}/register/`, form)
  }

  async formData(lang = `en`) {
    return await this.call(`get`, `form_data${this.RESOURCE}/?language=${lang}`)
  }
}

export default OnboardingModule
