
  import { mapGetters } from 'vuex'
  import ProductInformation from '~/components/marketplace/new_check/ProductInformation.vue'
  import PreviewTable from '~/components/marketplace/PreviewTable.vue'

  export default {
    name: `SummaryStep`,
    components: { PreviewTable, ProductInformation },
    data() {
      return {
        togglePreview: false
      }
    },
    computed: {
      ...mapGetters({
        currentCompany: `companies/getCurrentCompanyInfos`,
        totalCredit: `marketplace/totalCredits`,
        selectedChecks: `marketplace/selectedChecks`,
        selectedJobLocation: `marketplace/selectedJobLocation`,
        selectedReason: `marketplace/selectedReason`,
        selectedDossier: `marketplace/selectedDossier`,
        selectedCategory: `marketplace/selectedCategory`,
        candidateForm: `marketplace/candidateForm`,
        candidateFormsAnswers: `marketplace/candidateFormsAnswers`,
      })
    }
  }
