// Global Store
import { useRuntimeConfig } from '#imports'

export const state = () => ({
  lastLogin: null,
  timeoutLogout: false,
  timeoutMessageLogout: false,
  redirect: null,
  autoLogout: false,
  needFetchUser: false,
  askRating: false,
})

export const getters = {
  needFetchUser: (state) => state.needFetchUser,
  askRating: (state) => state.askRating,
  marketplaceExternalTimeout: (state) => state.marketplaceTimeoutLogout

}

export const mutations = {
  setLogin(state, payload) {
    state.lastLogin = payload
  },
  setRedirect(state, value) {
    state.redirect = value
  },
  clearRedirect(state) {
    state.redirect = null
  },
  toggleNeedFetchUser(state) {
    console.log(`toggle user`)
    state.needFetchUser = !state.needFetchUser
  },
  setAutoLogout(state, payload) {
    state.autoLogout = payload
  },
  clearAutoLogout(state) {
    state.autoLogout = false
  },
  setTimeoutMessageLogout(state, payload) {
    state.intervalMessageLogout = payload
  },
  clearTimeoutMessageLogout(state) {
    clearTimeout(state.intervalMessageLogout)
  },
  setTimeoutLogout(state, payload) {
    state.timeoutLogout = payload
  },
  clearTimeoutLogout(state) {
    clearInterval(state.timeoutLogout)
  },
  setAskRating(state, value) {
    state.askRating = value
  },
  setMarketplaceExternalTimeout(state, value) {
    state.marketplaceTimeoutLogout = value
  }
}

export const actions = {
  logout() {
    this.commit(`clearTimeoutLogout`)
    this.commit(`clearTimeoutMessageLogout`)
    this.$router.push({ path: `/logout` })
  },
  login() {
    this.commit(`setLogin`, this.auth?.user?.email)
  },
  refreshSetTimeout() {
    this.dispatch(`refreshSetTimeoutMessageLogout`)
    this.dispatch(`refreshSetTimeoutLogout`)
  },
  redirect({ state }) {
    console.log(`redirect`, state.redirect)
    const redirectURL = state.redirect
    this.commit(`clearRedirect`)
    this.$router.push(redirectURL)
  },
  refreshSetTimeoutMessageLogout({ rootState }) {
    if (!!rootState.external.token) {
      return
    }
    this.commit(`clearTimeoutMessageLogout`)
    this.commit(
      `setTimeoutMessageLogout`,
      setTimeout(() => {
        this.dispatch(`flashMessages/messageInfo`, {
          message: this.$i18n.t(`messages.inactive_message_before_logout`),
          time: 0
        })
      }, this.$auth.strategy.options.refreshToken.maxAge * 1000 * 0.666666)
    )
  },
  refreshSetTimeoutLogout({ rootState }) {
    if (!!rootState.external.token) {
      return
    }
    this.commit(`clearTimeoutLogout`)
    this.commit(
      `setTimeoutLogout`,
      setTimeout(() => {
        this.commit(`setAutoLogout`, true)
        this.dispatch(`logout`)
      }, this.$auth.strategy.options.refreshToken.maxAge * 1000)
    )
  },
  setAskRating(context, payload) {
    context.commit(`setAskRating`, payload.value)
  },
  createMarketplaceExternalTimeout({ state }) {
    if (state.marketplaceTimeoutLogout) {
      return null
    }
    const config = useRuntimeConfig()
    this.commit(
      `setMarketplaceExternalTimeout`,
      setInterval(() => {
        this.dispatch(`logoutMarketplaceExternalTimeout`)
      }, config.public.marketplace_external_logout_seconds * 1000)
    );
  },
  logoutMarketplaceExternalTimeout({ state }) {
    clearInterval(state.marketplaceTimeoutLogout)
    this.$router.push({ path: `/logout` })
  }

}
