

  export default {
    name: `MyCheckSedexCheckStatus`,
    components: {  },
    props: {
      myCheck: {
        type: Object,
        default: undefined
      },
    },
    data() {
      return {
        showModal: false,
        loading: false,
        resendSuccess: false
      }
    },
    computed: {
      orderedDate() {
        const timeString = this.$moment(this.myCheck.timeline.placed.date, `DD-MM-YYYY HH:mm`, true).isValid()
            ? this.$moment(this.myCheck.timeline.placed.date, `DD-MM-YYYY HH:mm`).format(`DD.MM.YYYY`)
            : `Invalid Date`;
        const [datePart] = timeString.split(` `);
        return datePart
      }
    },
    methods: {
      send() {
        this.loading = true
        this.$api.marketplace.resendAccess(this.myCheck.uuid)
          .then(() => {
            this.resendSuccess = true
            this.loading = false
          })
      },
    }
  }

