
  import { mapGetters } from 'vuex'
  import SelectionCard from '~/components/marketplace/SelectionCard.vue'

  export default {
    name: `MenuCategories`,
    components: { SelectionCard },
    async fetch() {
      await this.$store.dispatch(`marketplace/getCategories`)
    },
    async destroyed() {
      await this.$store.commit(`marketplace/defaultSelectedCategory`)
    },
    computed: {
      ...mapGetters({
        categories: `marketplace/categories`,
        selectedCategory: `marketplace/selectedCategory`,
      })
    },
    methods: {
      async select(event) {
        const category = event.item
        await this.$store.dispatch(`marketplace/getChecks`, { category })
        this.$emit(`select`, category)
      },
      async clearValues() {
        await this.$store.commit(`marketplace/defaultSelectedCategory`)
      }
    }
  }
