import HttpFactory from '../factory'

class MarketplaceExternal extends HttpFactory {
  RESOURCE = `/marketplace/external`
  async get() {
    return await this.raw(`GET`, `${this.RESOURCE}`)
  }

  async post(data) {
    return await this.raw(`PUT`, `${this.RESOURCE}/`, data)
  }

  async generatePdfFile(data) {
    return await this.raw(`POST`, `${this.RESOURCE}/generate_pdf/`, data)
  }

  async accountDeletion() {
    return await this.raw(`delete`, `${this.RESOURCE}/request_account_deletion/`)
  }
}

export default MarketplaceExternal
