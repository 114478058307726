import HttpFactory from '../factory'

class External extends HttpFactory {
  RESOURCE = `/external`
  async get() {
    return await this.raw(`GET`, `${this.RESOURCE}/`)
  }

  async update(data) {
    return await this.raw(`put`, `${this.RESOURCE}/`, data)
  }

  getOneDocument(documentUrl) {
    return this.raw(`get`, `${documentUrl}`)
  }

  contact(form) {
    return this.call(`post`, `${this.RESOURCE}/contact/`, form)
  }

  async delete(deleteLink) {
    return await this.raw(`GET`, `${deleteLink}`)
  }
}

export default External
