

  export default {
    name: `MyCheckCrifStatus`,
    props: {
      myCheck: {
        type: Object,
        default: undefined
      }
    },
  }

