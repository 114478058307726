

import IconHourGlass from '~/components/icons/IconHourGlass.vue'

export default {
  name: `DialogCheckOrderPending`,
  components: {
    IconHourGlass
  },
  props: {
    toggleDialog: {
      type: Boolean,
      default: false,
    }
  }
}
